import React, { Fragment } from "react";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BanIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import { useGeolocationContext} from '../../context'
import Spinner from "../../components/Spinner";

const Position = () => {
  const [open, setOpen] = useState(false);
  const { state: geolocation } = useGeolocationContext();
  const { error, loading = false, latitude = '', longitude = '' } = geolocation || {};

  return (
    <>
      <div className="relative cursor-pointer " onClick={() => setOpen(true)}>
        {
          loading && (
            <Spinner className="h-4 w-4 text-green-600 absolute inset-x-3 inset-y-3" />
          )
        }
        {
          error && (
            <BanIcon className="h-5 w-5 text-red-600 absolute inset-x-2.5 inset-y-2.5" />
          )
        }
        <LocationMarkerIcon
          className={`h-10 w-10 mr-5 ${loading || error ? 'text-gray-200' : ''}`}
        />
      </div>

      <Transition.Root show={open} as={Fragment}>

        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                    <div className="mt-3 text-center ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Posizione
                      </Dialog.Title>
                      <div className="mt-4">
                        <div className="flex mt-4">
                          <img className="w-6 mr-4" src="/images/position-loading.png" alt="Sigurtà" />
                          <p className="text-sm text-gray-500">In cerca della posizione</p>
                        </div>
                        <div className="flex mt-4">
                          <img className="w-6 mr-4" src="/images/position-error.png" alt="Sigurtà" />
                          <p className="text-sm text-gray-500">
                            Posizione non disponibile {error ? (error.message || '') : ''}
                          </p>
                        </div>
                        <div className="flex mt-4">
                          <img className="w-5 mr-4 ml-1" src="/images/position-normal.png" alt="Sigurtà" />
                          <p className="text-sm text-gray-500">
                            Posizione acquisita {latitude || ''} {longitude || ''}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Position;
