import React from "react";
import { CogIcon, RefreshIcon } from "@heroicons/react/outline";
import ModalPassword from "../ModalPassword";
import { useState } from "react";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useGeolocationContext } from '../../context'
import Position from "./position";

const Header = () => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const { refresh: refreshGeolocation } = useGeolocationContext();

  const flag = i18n.language === "en" ? "gb" : i18n.language;

  return (
    <>
      <div className="flex justify-between items-center px-6">
      <Link to="/settings">
            <ReactCountryFlag
              countryCode={flag}
              className="mr-10 text-6xl"
              svg
            />
          </Link>
        <img className="h-20" src="/images/logo_header.jpeg" alt="Sigurtà" />
        <div className="flex items-center">

          <RefreshIcon
            className="h-10 w-10 cursor-pointer mr-5"
            onClick={refreshGeolocation}
          />
          <Position />
          <CogIcon
            className="h-10 w-10 cursor-pointer"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      <ModalPassword open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
