import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactMarkdown from "react-markdown";

const ModalLocationDetail = ({ open, setOpen, point }) => {
  const {
    title: pointTitle,
    description: pointDescription,
    number: pointNumber,
    image: pointImage = {},
    audio: pointAudio = {},
    video: pointVideo = {},
    localizations: { data: localizationsData = [] } = {},
  } = point.attributes || {};

  const {
    attributes: {
      title: defaultTitle,
      description: defaultDescription,
      image: defaultImage = {},
      audio: defaultAudio = {},
      video: defaultVideo = {},
    } = {},
  } =
    localizationsData.find(({ attributes }) => attributes.locale === "it") ||
    {};

  const { attributes: imageAttrs = {} } = pointImage.data || {};
  const { attributes: audioAttrs = {} } = pointAudio.data || {};
  const { attributes: videoAttrs = {} } = pointVideo.data || {};

  const { attributes: defaultImageAttrs = {} } = defaultImage.data || {};
  const { attributes: defaultAudioAttrs = {} } = defaultAudio.data || {};
  const { attributes: defaultVideoAttrs = {} } = defaultVideo.data || {};

  const title = pointTitle || defaultTitle;
  const description = pointDescription || defaultDescription;
  const number =  pointNumber
  const imageUrl = imageAttrs.url || defaultImageAttrs.url || "";
  const audioUrl = audioAttrs.url || defaultAudioAttrs.url || "";
  const videoUrl = videoAttrs.url || defaultVideoAttrs.url || "";

  const audioExtension = audioUrl.split('.').pop().toLowerCase();
  const audioType =  audioExtension === 'm4a' || audioExtension === 'mp4' ? 'audio/mp4' : 'audio/mp3' 

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        style={{ marginTop: "80px" }}
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="h-full flex flex-col justify-between bg-white overflow-scroll shadow-xl transform transition-all">
            {audioUrl && (
              <audio
                className="invisible"
                src={audioUrl}
                autoPlay
                type={audioType}
                onEnded={() => setOpen(false)}
              />
            )}

            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div className="mt-4 flex space-x-4">
                  {/* Se c'è il video facciamo vedere solo il video (no audio e no immagine) */}
                  {videoUrl && (
                    <div className="relative">
                      <video
                        className="cursor-pointer w-screen"
                        src={videoUrl}
                        height="300px"
                        autoPlay loop muted
                      />
                    </div>
                  )}

                  {imageUrl && !videoUrl && (
                    <div className="w-full flex justify-center items-center backdrop-brightness-0">
                      <img
                        className="cursor-pointer object-contain"
                        src={imageUrl}
                        alt="Location description"
                        width="100%"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-6">
                {number && <span className="rounded-full bg-lime-600 px-3 py-1 text-white">N. {number}</span>}
                <h2 className="text-6xl font-bold mt-2">{title}</h2>
                <ReactMarkdown className="mt-4 text-4xl font-light leading-normal">{description}</ReactMarkdown>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setOpen(false)}
              >
                Chiudi
              </button>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalLocationDetail;
