import React from "react";
import { PhoneIcon } from "@heroicons/react/solid";

const EmergencyNumber = () => {
  return (
    <div className="flex select-none items-center text-xl fixed bottom-10 right-10 z-10 rounded-md border border-white bg-green-700 p-3 px-5 text-white">
      <PhoneIcon className="h-6 w-6 mr-3" />
      045 6371033
    </div>
  );
};
export default EmergencyNumber;
