import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import it from "./locales/it.json";
import en from "./locales/en.json";
import de from "./locales/de.json";
import fr from "./locales/fr.json";
import es from "./locales/es.json";
import ru from "./locales/ru.json";
import nl from "./locales/nl.json";

i18n.use(initReactI18next).init({
  resources: {
    it: {
      translation: it,
    },
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
    es: {
      translation: es,
    },
    ru: {
      translation: ru,
    },
    nl: {
      translation: nl,
    },
  },
  lng: "it",
  fallbackLng: "it",
  interpolation: {
    escapeValue: false,
  },
});
