function storageEstimateWrapper() {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    return navigator.storage.estimate();
  }

  if ('webkitTemporaryStorage' in navigator &&
    'queryUsageAndQuota' in navigator.webkitTemporaryStorage) {
    return new Promise(function (resolve, reject) {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        function (usage, quota) { resolve({ usage: usage, quota: quota }) },
        reject
      );
    });
  }

  // If we can't estimate the values, return a Promise that resolves with NaN.
  return Promise.reject(new Error('Estimate not supported'));
}

export const getStorageQuota = async () => {
  try {
    const { usage, quota } = await storageEstimateWrapper();
    const percentUsed = Math.round(usage / quota * 100);
    const usageInMib = Math.round(usage / (1024 * 1024));
    const quotaInMib = Math.round(quota / (1024 * 1024));
    
    return [usageInMib, quotaInMib, percentUsed];
  } catch (err) {
    console.log(err);
    return null;
  }
}
