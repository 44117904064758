import "leaflet/dist/leaflet.css";
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import * as L from "leaflet";
import { MapContainer, Marker, ImageOverlay } from "react-leaflet";
import { fetchPoints } from "../../api/points";
import ModalLocationDetail from "../ModalLocationDetail";
import { useGeolocationContext, useSettings } from "../../context";
import { useDebounce } from "react-use";
import { getDistanceFromLatLonInMeters } from "../../utils/geolocation";

const POI_DISTANCE = process.env.REACT_APP_POI_DISTANCE || 10;

const carIcon = new L.Icon({
  iconUrl: "/images/car.png",
  iconRetinaUrl: "/images/car.png",
  className: "h-12 w-12 -ml-6 -mt-10 !z-[700]",
});

const markerGreenIcon = new L.Icon({
  iconUrl: "/images/marker-green.svg",
  iconRetinaUrl: "/images/marker-green.svg",
  className: "h-12 w-12 -ml-6 -mt-12 ",
  //i margini negativi servono per allineare il marker nella posizione corretta
});

const markerYellowIcon = new L.Icon({
  iconUrl: "/images/marker-yellow.svg",
  iconRetinaUrl: "/images/marker-yellow.svg",
  className: "h-12 w-12 -ml-6 -mt-12",
});

const LeafletMap = () => {
  const { i18n } = useTranslation();
  const { state: geolocation } = useGeolocationContext();

  const [isInArea, setIsInArea] = useState(false);
  const [point, setPoint] = useState({});
  const [pointsDefault, setPointsDefault] = useState([]);
  const [points, setPoints] = useState([]);
  const [open, setOpen] = useState(false);

  const { showMarkers, activeMarkers } = useSettings();

  const bounds = L.latLngBounds([
    [45.355103, 10.72525183],
    [45.367424, 10.73716783],
  ]);

  useEffect(() => {
    if (!geolocation.loading && !geolocation.error) {
      let isInArea = false;

      let mergedData = new Map();
      pointsDefault.concat(points).forEach(function (obj) {
        mergedData.set(
          obj.attributes.latitude,
          Object.assign(mergedData.get(obj.attributes.latitude) || {}, obj)
        );
      });
      const allPoints = Array.from(mergedData.values());

      for (const p of allPoints) {
        const { attributes } = p;

        const calculatedDistance = getDistanceFromLatLonInMeters(
          geolocation.latitude,
          geolocation.longitude,
          attributes.latitude,
          attributes.longitude
        );

        if (calculatedDistance <= POI_DISTANCE) {
          isInArea = true;
          setPoint(p);
          break;
        }
      }

      setIsInArea(isInArea);
    }
    if (geolocation.error) {
      console.log(geolocation.error.message);
      console.log(JSON.stringify(geolocation.error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation]);

  useDebounce(
    () => {
      if (isInArea) {
        setOpen(true);
      }
    },
    100,
    [isInArea]
  );

  useEffect(() => {
    (async () => {
      const dataDefault = await fetchPoints({ locale: "it" });
      const data = await fetchPoints({ locale: i18n.language });
      setPointsDefault(dataDefault);
      setPoints(data);
    })();
  }, [i18n.language]);

  const center = bounds.getCenter();

  //Unisce i POI di default (it) con quelli presenti nelle altre lingue
  let mergedData = new Map();
  pointsDefault.concat(points).forEach(function (obj) {
    mergedData.set(
      obj.attributes.latitude,
      Object.assign(mergedData.get(obj.attributes.latitude) || {}, obj)
    );
  });
  const allPoints = Array.from(mergedData.values());

  return (
    <Fragment>
      <MapContainer
        id="map"
        zoom={16}
        minZoom={16.6}
        center={center}
        dragging={false}
        zoomSnap={false}
        zoomDelta={false}
        trackResize={false}
        touchZoom={false}
        scrollWheelZoom={false}
        zoomControl={false}
        doubleClickZoom={false}
        closePopupOnClick={false}
        attributionControl={false}
        style={{ height: "calc(100vh - 80px)" }}
      >
        {/* Non ci servono perchè usiamo solo l'overlay */}
        {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}

        <ImageOverlay url="/images/mapoverlay.jpg" bounds={bounds} />

        {showMarkers &&
          allPoints.map((point) => {
            const { id, attributes } = point;
            const { type, latitude, longitude } = attributes;

            return (
              <Marker
                key={id}
                icon={type === "Poi" ? markerGreenIcon : markerYellowIcon}
                position={[
                  parseFloat(latitude) || 45.356,
                  parseFloat(longitude) || 10.7278,
                ]}
                eventHandlers={{
                  click: () => {
                    if (!activeMarkers) return;

                    setPoint(point);
                    setOpen(true);
                  },
                }}
              />
            );
          })}

        {!geolocation.loading && !geolocation.error && (
          <Marker
            icon={carIcon}
            position={[
              parseFloat(geolocation.latitude),
              parseFloat(geolocation.longitude),
            ]}
          />
        )}
      </MapContainer>

      <ModalLocationDetail open={open} setOpen={setOpen} point={point} />
    </Fragment>
  );
};

export default LeafletMap;
