import { useEffect, useState } from 'react';

const GEOLOCATION_OPTIONS = Object.freeze({
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: Infinity,
});

const getInitialState = () => ({
  loading: true,
  accuracy: null,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: null,
  longitude: null,
  speed: null,
  timestamp: Date.now(),
});

const useGeolocation = () => {
  const [state, setState] = useState(getInitialState());
  let mounted = true;
  let timeoutId, watchId;

  const onEvent = (event) => {
    mounted && setState({
      loading: false,
      accuracy: event.coords.accuracy,
      altitude: event.coords.altitude,
      altitudeAccuracy: event.coords.altitudeAccuracy,
      heading: event.coords.heading,
      latitude: event.coords.latitude,
      longitude: event.coords.longitude,
      speed: event.coords.speed,
      timestamp: event.timestamp,
    });
  };

  const onEventError = (error) => {
    mounted && setState((oldState) => ({ ...oldState, loading: false, error }));
  }

  const cleanUp = () => {
    mounted = false;
    timeoutId && clearTimeout(timeoutId);
    timeoutId = undefined;
    watchId && navigator.geolocation.clearWatch(watchId);
    watchId = undefined;
  };

  const start = () => {
    mounted = true;
    navigator.geolocation.getCurrentPosition(onEvent, onEventError, GEOLOCATION_OPTIONS);
    watchId = navigator.geolocation.watchPosition(onEvent, onEventError, GEOLOCATION_OPTIONS);
  };

  const refresh = () => {
    cleanUp();
    setState(getInitialState());
    timeoutId = setTimeout(() => {
      timeoutId = undefined;
      start();
    }, 1000);
  };

  useEffect(() => {
    start();
    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { state, refresh };
};

export default useGeolocation;
