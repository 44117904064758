import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useSettings } from "../context";
import ModalAlert from "../components/ModalAlert";
import ReactCountryFlag from "react-country-flag";
import * as serviceWorkerRegistration from "./../serviceWorkerRegistration";

const Settings = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const { state } = useLocation();
  const { isAdmin = false } = state || {}
  const navigate = useNavigate();
  const {
    i18n,
    languages,
    showMarkers,
    activeMarkers,
    changeLanguage,
    toggleShowMarkers,
    toggleActiveMarkers,
    fetchAndStoreMedia,
    fetchMediaProgress: progress,
    fetchQuota,
    quota,
  } = useSettings();

  const packageJson = require("../../package.json");
  const appVersion = packageJson.version;
  const lastUpdate = localStorage.getItem("lastUpdate");

  function updateOnlineStatus(event) {
    setIsOnline(event.type === "online");
  }

  useEffect(() => {
    fetchQuota()

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const update = async () => {
    await fetchAndStoreMedia();
  };

  const refresh = async () => {
    await serviceWorkerRegistration.unregister()
    alert('L\'app è aggiornata')
    setTimeout(() => window.location.reload())
  }

  return (
    <Fragment>
      <div className="flex justify-between items-center px-6">
        <img className="h-20" src="/images/logo_header.jpeg" alt="Sigurtà" />
        <XIcon
          className="h-10 w-10 cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      <div
        style={{ height: "calc(100vh - 80px)" }}
        className="flex flex-col justify-between p-6"
      >
        <div className="grid grid-cols-2 gap-2">
          {languages.map(({ name, code }, index) => {
            const flag = code === "en" ? "gb" : code;
            return (
              <button
                key={index}
                className={classNames(
                  "text-center py-12 m-2 rounded-lg align-middle hover:border-green-700 border-2 ease-in-out duration-300",
                  {
                    "bg-green-700 text-white": code === i18n.language,
                  }
                )}
                onClick={() => changeLanguage(code)}
              >
                <ReactCountryFlag
                  countryCode={flag}
                  className="mr-5 text-3xl"
                  svg
                />
                {name}
              </button>
            );
          })}
        </div>

        {isAdmin && (
          <div className="w-full flex flex-col justify-center">
            {progress > -1 && (
              <div className="w-full bg-gray-200 rounded-lg mb-4">
                <div
                  className="bg-green-700 text-sm font-medium text-blue-100 text-center p-0.5 leading-none rounded-lg"
                  style={{ width: `${progress > 10 ? progress : 10}%` }}
                >
                  {progress}%
                </div>
              </div>
            )}
            <ModalAlert
              condition={!isOnline}
              description="Devi essere connesso ad internet per aggiornare i contenuti"
              callback={update}
            >
              <button
                disabled={progress > -1}
                className="mb-5 flex items-center justify-center font-semibold py-3 px-4 border-2 border-green-700 rounded-lg ease-in-out duration-300"
              >
                Forza l’aggiornamento dei contenuti
              </button>
            </ModalAlert>

            <ModalAlert
              condition={!isOnline}
              description="Devi essere connesso ad internet per aggiornare l'app"
              callback={refresh}
              >
                <button
                className="mb-5 flex items-center justify-center font-semibold py-3 px-4 border-2 border-green-700 rounded-lg ease-in-out duration-300"
              >
                Aggiorna l'app
              </button>
            </ModalAlert>

            <button
              onClick={toggleShowMarkers}
              className={classNames(
                "mb-5 flex items-center justify-center font-semibold py-3 px-4 border-2 border-green-700 rounded-lg ease-in-out duration-300",
                {
                  "bg-green-700 text-white": !showMarkers,
                }
              )}
            >
              Nascondi Marker
            </button>

            <button
              onClick={toggleActiveMarkers}
              className={classNames(
                "flex items-center justify-center font-semibold py-3 px-4 border-2 border-green-700 rounded-lg ease-in-out duration-300",
                {
                  "bg-green-700 text-white": !activeMarkers,
                }
              )}
            >
              Disabilita click su marker
            </button>
            <p className="text-center my-10 font-light text-gray-700 text-xl">
              {quota && (
                <span className="text-center">
                  Scaricati: <span className="text-lime-800 font-bold">{quota[0]}</span> MiB<br />
                </span>
              )}
              
              {lastUpdate} <br></br> Versione <span className="text-lime-800 font-bold">{appVersion}</span>

              {quota && (
                <span className="text-base text-gray-400 mt-6">
                  <br/>
                  Spazio disponibile sul tablet {quota[1]} MiB. Spazio usato: {quota[2]}%. <br/><br/>
                </span>
              )}
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Settings;
