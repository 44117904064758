import { createContext, useContext } from 'react';
import { useGeolocation } from '../hooks'

const GeolocationContext = createContext({});

const GeolocationProvider = ({ children }) => {
  const geolocation = useGeolocation();

  return (
    <GeolocationContext.Provider value={geolocation}>
      {children}
    </GeolocationContext.Provider>
  );
};

const useGeolocationContext = () => useContext(GeolocationContext);

export { GeolocationProvider, useGeolocationContext };
