import qs from "qs";

const MAX_FETCH_ATTEMPTS = 3;

const fetchAndRetry = async (url = '') => {
  let attempts = 0;
  let error = null;

  while (attempts < MAX_FETCH_ATTEMPTS) {
    try {
      return await fetch(url);
    } catch (e) {
      attempts++;
      error = e;
    }
    await new Promise((resolve) => setTimeout(resolve, 100 * attempts));
  }
  if (error) throw error;
};

export const fetchPoints = async ({ locale }) => {
  const query = qs.stringify(
    {
      populate: [
        "image",
        "audio",
        "video",
        "localizations",
        "localizations.image",
        "localizations.audio",
        "localizations.video",
      ],
      locale,
      pagination: {
        page: 1,
        pageSize: 100,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  try {
    const response = await fetchAndRetry(
      `${process.env.REACT_APP_API_URL}/api/points?${query}`
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = await response.json();

    return data.data;
  } catch (error) {
    return [];
  }
};

export const fetchMediaOfPoint = async (point) => {
  const { attributes } = point || {};
  const { audio, image, video } = attributes || {};

  const { attributes: imageAttrs = {} } = image.data || {};
  const { attributes: audioAttrs = {} } = audio.data || {};
  const { attributes: videoAttrs = {} } = video.data || {};

  const promises = [];
  if (imageAttrs.url) promises.push(fetchAndRetry(imageAttrs.url).catch(err => console.log('media image error', err)));
  if (audioAttrs.url) promises.push(fetchAndRetry(audioAttrs.url).catch(err => console.log('media audio error', err)));
  if (videoAttrs.url) promises.push(fetchAndRetry(videoAttrs.url).catch(err => console.log('media videoAttrs error', err)));

  return Promise.all(promises);
};

export const fetchFlag = async (code) => {
  const flag = code === "en" ? "gb" : code;
  try {
    await fetchAndRetry(
      `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${flag}.svg`
    );
  } catch (err) {
    console.log('Flag err', err)
  }
};
