import React, { Fragment, useEffect, useRef, useState } from "react";
import LeafletMap from "../components/Map";
import EmergencyNumber from "../components/EmergencyNumber";
import Header from "../components/Header";
import { FullScreenLoader } from '../components/FullScreenLoader';
import { useSettings } from "../context";

const Home = ({ isLoadingCompletedOnceRef = { current: false } }) => {
  const { fetchAndStoreMedia, fetchMediaProgress } = useSettings();
  const [isLoading, setIsLoading] = useState(!isLoadingCompletedOnceRef.current);
  const isFirstFetch = useRef(true);
  const isFirstFetchMediaProgressChange = useRef(true);

  useEffect(() => {
    if (isLoadingCompletedOnceRef.current || !isFirstFetch.current) return;

    isFirstFetch.current = false;
    void fetchAndStoreMedia({
      clearCacheBefore: false,
      showAlerts: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingCompletedOnceRef.current || fetchMediaProgress !== -1) return;

    if (isFirstFetchMediaProgressChange.current) {
      isFirstFetchMediaProgressChange.current = false;
      return;
    }

    setIsLoading(false);
    isLoadingCompletedOnceRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMediaProgress]);

  return (
    <Fragment>
      <Header />
      {isLoading ? (
        <FullScreenLoader progress={fetchMediaProgress} />
      ) : (
        <Fragment>
          <LeafletMap />
          <EmergencyNumber />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
