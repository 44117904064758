import React, { createContext, useCallback, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchFlag, fetchMediaOfPoint, fetchPoints } from '../api/points';
import { formatNow } from '../utils/date';
import { getStorageQuota } from '../utils/storage';

const SettingsContext = createContext();

const languages = [
  { name: "Italiano", code: "it" },
  { name: "English", code: "en" },
  { name: "Deutsch", code: "de" },
  { name: "Français", code: "fr" },
];

const SettingsProvider = ({ children }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [showMarkers, setShowMarkers] = useState(true);
  const [activeMarkers, setActiveMarkers] = useState(true);
  const [fetchMediaProgress, setFetchMediaProgress] = useState(-1);
  const [quota, setQuota] = useState(null);

  useEffect(() => {
    const languageCode = localStorage.getItem("@sigurta/language");
    const showMarkers = localStorage.getItem("@sigurta/show-markers");
    const activeMarkers = localStorage.getItem("@sigurta/active-markers");

    if (languageCode) {
      void i18n.changeLanguage(languageCode);
    }

    if (showMarkers) {
      setShowMarkers(showMarkers === "true");
    }

    if (activeMarkers) {
      setActiveMarkers(activeMarkers === "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = useCallback((code) => {
    void i18n.changeLanguage(code);
    localStorage.setItem("@sigurta/language", code);
  }, [i18n]);

  const toggleShowMarkers = useCallback(() => {
    setShowMarkers((showMarkers) => {
      localStorage.setItem("@sigurta/show-markers", `${!showMarkers}`);

      return !showMarkers;
    });
  }, []);

  const toggleActiveMarkers = useCallback(() => {
    setActiveMarkers((activeMarkers) => {
      localStorage.setItem("@sigurta/active-markers", `${!activeMarkers}`);

      return !activeMarkers;
    });
  }, []);

  const cacheDelete = useCallback(async () => {
    if (!window.caches) return;

    const names = ['media', 'points'];
    try {
      for (const name of names) {
        await caches.delete(name);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchQuota = useCallback(async () => {
    try {
      const quota = await getStorageQuota();
      setQuota(quota)
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchAndStoreMedia = useCallback(async ({
    clearCacheBefore = true,
    showAlerts = true,
  } = {}) => {
    try {
      setFetchMediaProgress(0);
      clearCacheBefore && await cacheDelete();

      const points = [];

      for (const lang of languages) {
        await fetchFlag(lang.code);
        points.push(...(await fetchPoints({ locale: lang.code })));
      }

      for (let i = 0; i < points.length; i++) {
        await fetchMediaOfPoint(points[i]);
        setFetchMediaProgress(Math.round((i / points.length) * 100));
      }

      setTimeout(async () => {
        void fetchQuota();
        localStorage.setItem(
          "lastUpdate",
          `Ultimo aggiornamento: ${formatNow()}`
        );
        showAlerts && alert("Aggiornamento completato!");
        navigate("/", { replace: true });
      }, 200);
    } catch (error) {
      console.log("error", error);
      showAlerts && alert("Errore: aggiornamento NON completato!");
    } finally {
      setFetchMediaProgress(-1);
    }
  }, [cacheDelete, fetchQuota, navigate]);

  return (
    <SettingsContext.Provider
      value={{
        i18n,
        languages,
        showMarkers,
        activeMarkers,
        changeLanguage,
        toggleShowMarkers,
        toggleActiveMarkers,
        cacheDelete,
        fetchAndStoreMedia,
        fetchMediaProgress,
        fetchQuota,
        quota,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => useContext(SettingsContext);

export { useSettings, SettingsProvider };
