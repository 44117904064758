import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GeolocationProvider, SettingsProvider } from "./context";
import Home from "./pages/Home";
import Settings from "./pages/Settings";

const wakeScreen = async () => {
  if (!navigator.wakeLock) return;

  try {
    await navigator.wakeLock.request('screen');
  } catch (err) {
    console.log(err)
  }
};

const App = () => {
  const isLoadingCompletedOnceRef = useRef(false);

  useEffect(() => {
    void wakeScreen();
  }, [])

  return (
    <GeolocationProvider>
      <Router>
        <SettingsProvider>
          <Routes>
            <Route
              path="/"
              element={<Home isLoadingCompletedOnceRef={isLoadingCompletedOnceRef} />}
            />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </SettingsProvider>
      </Router>
    </GeolocationProvider>
  );
}

export default App;
